import SVG from 'react-inlinesvg';

import React, { useEffect } from "react"
import { getRandomIndex, isMobile } from "../utils";
import { useState } from "react";
import classNames from 'classnames';

const Testimonial = ({ testimonials, shouldNotLazyLoad, title, imageBorderRadiusForServices, logosBgColor, sectionBackgroundColor, isIndustriesPage }) => {
  const randomIndex =  getRandomIndex(testimonials); 
  const [testimonial, setTestimonial ] = useState(null);

  useEffect(()=> {
    setTestimonial(testimonials[randomIndex]);
  }, []);

  return (
    <>
      {testimonial && (
        <div className="row align-items-center">    
          <div className="col-md-10 offset-md-1">
            {title && (
              <div className="row d-flex justify-content-between">
                <div className={classNames("col-xs-12 col col-sm-6 col-md-6 ", {"col-lg-6": isIndustriesPage})}>
                  <header className="header">
                    <h1 className="header__heading header__heading--space text-xs-left">
                      {title}
                    </h1>
                  </header>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col">
                <div className="horizontal-card horizontal-card--large">
                  <div className="horizontal-card__media" >
                    {testimonial.media && (
                      <img 
                        src={testimonial.media.url} 
                        alt={testimonial.media.alternativeText} 
                        {...(!shouldNotLazyLoad && { loading: 'lazy' })} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                      />
                    )}
                    {testimonial.logo && (
                      <div className="horizontal-card-teaser" style={{backgroundColor: sectionBackgroundColor}} >
                        <div className="horizontal-card-inner" style={logosBgColor === "color" ? {} : {fill: logosBgColor }}>                              
                          <SVG
                            src={testimonial.logo.url}
                          />   
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="horizontal-card__body">
                    <blockquote>
                      {/* <p className="horizontal-card__longest-body">{longestBody}</p> */}
                      <p className="horizontal-card__longest-body">{testimonial.description}</p>
                      <cite>
                        <b>{testimonial.name}</b>
                        {testimonial.position}<br/>
                        {testimonial.company}
                      </cite>
                    </blockquote>

                  </div>  
                </div>
              </div>
            </div>            
          </div>
        </div>
      ) }
    </>

  );
}

export default Testimonial;
